define("securitytrax-ember-ui/components/pages/administration/company-settings/company-settings-index/component", ["exports", "ember-concurrency", "lodash", "securitytrax-ember-ui/helpers/feature-is-enabled"], function (_exports, _emberConcurrency, _lodash, _featureIsEnabled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    navigation: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    enhancedFeatures: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    // Company settings route section mappings
    contentManagementRouteMappings: null,
    organizationRouteMappings: null,
    systemRouteMappings: null,
    equipmentRouteMappings: null,
    integrationsRouteMappings: null,
    hasEdocFeature: Ember.computed(function () {
      return this.enhancedFeatures.hasFeature('edoc');
    }),
    hasFinancingFeature: Ember.computed(function () {
      return this.enhancedFeatures.hasFeature('consumerFinancing');
    }),
    hasQuickbooksFeature: Ember.computed(function () {
      return this.enhancedFeatures.hasFeature('quickbooks');
    }),
    initializeNavTask: (0, _emberConcurrency.task)(function* () {
      var loggedInUser = yield this.sessionData.getUser();
      var hasEdocFeature = yield this.enhancedFeatures.hasFeature('edoc');
      var hasFinancingFeature = yield this.enhancedFeatures.hasFeature('consumerFinancing');
      var hasQuickbooksFeature = yield this.enhancedFeatures.hasFeature('quickbooks');
      var companyPublicInfo = yield this.sessionData.getPublicCompanyInfo();
      this.set('loggedInUser', loggedInUser);
      yield this.navigation;
      this.organizationRouteMappings = _lodash.default.sortBy([{
        route: 'office-locations',
        icon: 'icn-target',
        name: this.intl.t('pages.administration.organization.officeLocations.title')
      }, {
        route: 'user-titles',
        icon: 'icn-badge',
        name: this.intl.t('pages.administration.organization.userTitles.title')
      }], ['sort', 'name']);
      this.systemRouteMappings = _lodash.default.sortBy([{
        route: 'form-defaults',
        icon: 'icn-document',
        name: this.intl.t('pages.administration.company_settings.global_settings.form_defaults.title')
      }, {
        route: 'global-settings.company',
        icon: 'icn-cog-hollow',
        name: this.intl.t('pages.administration.system.globalSettings.title'),
        hidden: !this.grants.getIndividualGrant('global_settings.view')
      }, {
        route: 'state-sales-tax-settings',
        icon: 'icn-bar-chart',
        name: this.intl.t('pages.administration.system.stateSalesTaxSettings.title')
      }, {
        route: 'tags',
        icon: 'icn-tag',
        name: this.intl.t('pages.administration.system.tags.title')
      }, //Tool build for NRG is no longer needed
      //{ route: 'upload-tool', icon: 'icn-upload', name: this.intl.t('pages.administration.system.uploadTool.title') },
      {
        route: 'payables',
        icon: 'icn-billing',
        name: this.intl.t('pages.administration.human_resources.payables'),
        hidden: !(0, _featureIsEnabled.featureIsEnabled)('payroll')
      }], ['sort', 'name']);
      this.equipmentRouteMappings = _lodash.default.sortBy([{
        route: 'equipment',
        icon: 'icn-feature-video',
        name: this.intl.t('pages.administration.equipment.title')
      }, {
        route: 'equipment-kits',
        icon: 'icn-box-storage',
        name: this.intl.t('pages.administration.equipment.equipmentKits.title')
      }, {
        route: 'equipment-packages',
        icon: 'icn-installation-installed',
        name: this.intl.t('pages.administration.equipment.equipmentPackages.title')
      }, {
        route: 'equipment-vendors',
        icon: 'icn-enterprise-open',
        name: this.intl.t('pages.administration.equipment.equipmentVendors.title')
      }], ['sort', 'name']);
      this.integrationsRouteMappings = _lodash.default.sortBy([{
        route: 'consumer-financing',
        icon: 'icn-money',
        name: this.intl.t('pages.customers.accounting.financing.consumer_financing'),
        hidden: !(0, _featureIsEnabled.featureIsEnabled)(['consumerFinancing', companyPublicInfo && companyPublicInfo.ui_feature_flags.consumer_financing && companyPublicInfo.ui_feature_flags.consumer_financing.enabled]) || !hasFinancingFeature
      }, {
        route: 'credit-bureaus',
        icon: 'icn-seal',
        name: this.intl.t('pages.administration.integrations.credit_bureaus.title')
      }, {
        route: 'electronic-document-providers',
        icon: 'icn-contract-edit',
        name: this.intl.t('pages.administration.integrations.electronic_document_providers.title'),
        hidden: !hasEdocFeature
      }, {
        route: 'interactive-services-providers',
        icon: 'icn-service-provider',
        name: this.intl.t('pages.administration.integrations.interactiveServicesProviders.title')
      }, {
        route: 'monitoring-company-list',
        icon: 'icn-shield-checked',
        name: this.intl.t('pages.administration.integrations.monitoringCompanies.title')
      }, {
        route: 'partner-company-list',
        icon: 'icn-building',
        name: this.intl.t('pages.administration.integrations.sources_partnerCompanies'),
        hidden: !this.grants.getIndividualGrant('lead_companies.view')
      }, {
        route: 'payment-processing',
        icon: 'icn-conversion',
        name: this.intl.t('pages.administration.integrations.payment_processing.title'),
        hidden: !this.grants.getIndividualGrant('processing_gateways.view')
      }, {
        route: 'accounting-providers-sync',
        icon: 'icn-money',
        name: this.intl.t('pages.administration.integrations.accountingProvidersSync.title'),
        hidden: !(0, _featureIsEnabled.featureIsEnabled)('quickbooks') || !this.grants.getIndividualGrant('quickbooks_integrations.view') || !hasQuickbooksFeature
      }], ['sort', 'name']);
      this.contentManagementRouteMappings = _lodash.default.sortBy([{
        route: 'appointment-subtypes',
        icon: 'icn-date',
        name: this.intl.t('pages.administration.content_management.appointment_detail_types.title')
      }, {
        route: 'account-classes',
        icon: 'icn-list',
        name: this.intl.t('pages.administration.content_management.account_class.title')
      }, {
        route: 'activation-fees',
        icon: 'icn-money',
        name: this.intl.t('pages.administration.content_management.activation_fee.title')
      }, {
        route: 'contract-terms',
        icon: 'icn-contract-ok',
        name: this.intl.t('pages.administration.content_management.contract_term.title')
      }, {
        route: 'customer-cancel-reasons',
        icon: 'icn-user-control-closed',
        name: this.intl.t('pages.administration.content_management.customer_cancel_reason.title')
      }, {
        route: 'customer-warranties',
        icon: 'icn-contract-ok',
        name: this.intl.t('pages.administration.content_management.customer_warranty.title')
      }, {
        route: 'dealer-numbers',
        icon: 'icn-clipboard',
        name: this.intl.t('pages.administration.content_management.dealer_number.title')
      }, {
        route: 'dry-run-reasons',
        icon: 'icn-car-stop',
        name: this.intl.t('pages.administration.content_management.dry_run_reasons.title')
      }, {
        route: 'funding',
        icon: 'icn-bank',
        name: this.intl.t('pages.administration.content_management.funding.title')
      }, {
        route: 'invoice-billable-items',
        icon: 'icn-billing-company',
        name: this.intl.t('pages.administration.content_management.invoice_billable_item.title')
      }, {
        route: 'monthly-monitoring-rates',
        icon: 'icn-monies',
        name: this.intl.t('pages.administration.content_management.mmr.title')
      }, {
        route: 'note-types',
        icon: 'icn-note',
        name: this.intl.t('pages.administration.content_management.note_types.title')
      }, {
        route: 'order-templates',
        icon: 'icn-feature-automation',
        name: this.intl.t('pages.administration.content_management.order_template.title')
      }, {
        route: 'customer-workflows',
        icon: 'icn-admin-flow',
        name: this.intl.t('pages.administration.content_management.workflows.title')
      }, {
        route: 'customer-payment-types',
        icon: 'icn-creditcard',
        name: this.intl.t('pages.administration.content_management.customer_payment_types.title')
      }, {
        route: 'work-order-templates',
        icon: 'icn-copy',
        name: this.intl.t('pages.administration.content_management.work_order_templates.title')
      }, {
        route: 'task-templates',
        icon: 'icn-plan',
        name: this.intl.t('pages.administration.content_management.task_templates.title')
      }, {
        route: 'work-order-types',
        icon: 'icn-clipboard',
        name: this.intl.t('pages.administration.content_management.work_order_types.title')
      }, {
        route: 'at-risk-entry-settings',
        icon: 'icn-healthcheck',
        name: this.intl.t('pages.administration.content_management.at_risk_entry_settings.title')
      }, {
        route: 'message-templates',
        icon: 'icn-email',
        name: this.intl.t('pages.administration.content_management.message_templates.title'),
        hidden: !(0, _featureIsEnabled.featureIsEnabled)('customerMessages')
      }, {
        route: 'lead-statuses',
        icon: 'icn-group',
        name: this.intl.t('pages.administration.content_management.lead_statuses.title')
      }, {
        route: 'equipment-return-reasons',
        icon: 'icn-shades-unknown',
        name: this.intl.t('pages.administration.content_management.equipment_return_reasons.title')
      }, {
        route: 'customer-notes-contact-settings',
        icon: 'icn-note',
        name: this.intl.t('pages.administration.content_management.customer_notes_contact_settings.title')
      }, {
        route: 'scheduling-profiles',
        icon: 'icn-date',
        name: this.intl.t('pages.administration.content_management.scheduling_profiles.title')
      }, {
        route: 'invoice-quote-templates',
        icon: 'icn-billing-company',
        name: this.intl.t('pages.administration.content_management.invoice_quote_templates.title')
      }, {
        route: 'invoice-terms',
        icon: 'icn-billing-company',
        name: this.intl.t('pages.administration.content_management.invoice_terms.title')
      }], ['sort', 'name']);
    }).on('init')
  });

  _exports.default = _default;
});