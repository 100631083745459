define("securitytrax-ember-ui/customers/information/route", ["exports", "securitytrax-ember-ui/customers/base-route", "ember-concurrency", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _emberConcurrency, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    userService: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    model: function () {
      var {
        customer,
        tags
      } = this._super(...arguments);

      return this.setupModelTask.perform(customer, tags);
    },
    setupModelTask: (0, _emberConcurrency.task)(function* (customer, tags) {
      var grants = this.grants;
      var store = this.store;
      var resolvedGrants = null;
      var salesman = this.userService.getUsersList('sales_rep', customer);
      var techList = this.userService.getUsersList('tech', customer);

      try {
        resolvedGrants = yield Ember.RSVP.hash({
          customerSystemInfoGrant: grants.get('getIndividualGrantAsync').perform('customer_system_information.view'),
          customerOrderInfoGrant: grants.get('getIndividualGrantAsync').perform('customer_order_information.view'),
          customerEquipmentGrant: grants.get('getIndividualGrantAsync').perform('customer_equipment.view')
        });
      } catch (e) {//Do nothing
      }

      return Ember.RSVP.hash({
        customer,
        tags,
        technicianList: techList,
        salesReps: salesman,
        leadReps: salesman,
        trainers: salesman,
        customerEquipment: resolvedGrants.customerEquipmentGrant ? customer.get('customerEquipment') : null,
        customerOrderInformation: resolvedGrants.customerOrderInfoGrant ? store.findRecord('customerOrderInformation', customer.get('id')) : null,
        customerSystemInformation: resolvedGrants.customerSystemInfoGrant ? store.findRecord('customerSystemInformation', customer.get('id')) : null,
        orderTemplates: store.findAll('orderTemplate'),
        equipment: store.query('equipment', {
          include: 'equipment_type',
          limit: 100,
          sort: 'name'
        }),
        equipmentWireTypes: store.findAll('equipmentWireType'),
        equipmentStatus: store.findAll('equipmentStatus'),
        packages: store.findAll('package', {
          include: 'package_equipment,package_equipment.equipment'
        }),
        monitoringCompanies: store.findAll('monitoringCompany'),
        monitoringPlans: store.query('monitoringPlan', {
          filter: 'company_config=true',
          limit: 0
        })
      });
    })
  });

  _exports.default = _default;
});