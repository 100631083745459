define("securitytrax-ember-ui/router", ["exports", "securitytrax-ember-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    // http://stackoverflow.com/a/26699958
    // https://guides.emberjs.com/v1.10.0/routing/
    // https://www.drupal.org/node/13148
    rootURL: '/' + _environment.default['securitytrax-ember-ui']['profile'] + '/'
  });
  Router.map(function () {
    this.route('customers', {
      path: '/customers/:id'
    }, function () {
      this.route('view', function () {
        this.route('edit-create-customer-contact', {
          path: '/:ccid/edit-create-customer-contact'
        });
        this.route('edit-create-note', {
          path: '/:nid/edit-create-note'
        });
        this.route('edit-create-appointment', {
          path: '/:ceid/edit-create-appointment'
        });
        this.route('edit-create-funding', {
          path: '/:fid/edit-create-funding'
        });
        this.route('edit-create-payable', {
          path: '/:pid/edit-create-payable'
        });
        this.route('edit-create-invoice', {
          path: '/:iid/edit-create-invoice'
        });
        this.route('edit-create-quote', {
          path: '/:qid/edit-create-quote'
        });
        this.route('manage-adt', function () {
          this.route('manage-account');
        });
        this.route('manage-adc');
        this.route('manage-alula');
        this.route('manage-rapid-response');
        this.route('manage-united-central-control');
        this.route('manage-avantguard');
        this.route('manage-cms');
        this.route('manage-dynamark-monitoring');
        this.route('manage-security-partners');
        this.route('manage-legacy-security-services');
        this.route('manage-american-two-way');
        this.route('manage-cops');
        this.route('manage-affiliated-monitoring');
        this.route('manage-api');
        this.route('manage-alarm-central');
      });
      this.route('equipment', function () {
        this.route('edit-create-customer-equipment', {
          path: '/:ceid/edit-create-customer-equipment'
        });
      }); ////////////////////////////////////////////////////////////////
      // accounting routes

      this.route('accounting', function () {
        this.route('edit-create-funding', {
          path: '/:fid/edit-create-funding'
        });
        this.route('edit-create-payable', {
          path: '/:pid/edit-create-payable'
        });
        this.route('edit-create-invoice', {
          path: '/:iid/edit-create-invoice'
        });
        this.route('edit-create-quote', {
          path: '/:qid/edit-create-quote'
        });
      });
      this.route('accounting.billing-billing-information', {
        path: '/accounting/billing-information'
      });
      this.route('accounting.billing-payment-methods', {
        path: '/accounting/payment-methods'
      }, function () {
        this.route('edit-create-payment-method', {
          path: '/:pid/edit-create-payment-method'
        });
      });
      this.route('accounting.billing-statements', {
        path: '/accounting/statements'
      });
      this.route('accounting.invoices-invoices', {
        path: '/accounting/invoices'
      }, function () {
        this.route('edit-create-invoice', {
          path: '/:iid/edit-create-invoice'
        });
      });
      this.route('accounting.invoices-recurring-invoices', {
        path: '/accounting/recurring-invoices'
      }, function () {
        this.route('edit-create-recurring-invoice', {
          path: '/:riid/edit-create-recurring-invoice'
        });
      });
      this.route('accounting.invoices-refund-receipts', {
        path: '/accounting/refund-receipts'
      }, function () {
        this.route('edit-create-refund-receipt', {
          path: '/:rrid/edit-create-refund-receipt'
        });
      });
      this.route('accounting.invoices-quotes', {
        path: '/accounting/quotes'
      }, function () {
        this.route('edit-create-quote', {
          path: '/:qid/edit-create-quote'
        });
      });
      this.route('accounting.invoices-credit-memos', {
        path: '/accounting/credit-memos'
      }, function () {
        this.route('edit-create-credit-memo', {
          path: '/:cmid/edit-create-credit-memo'
        });
      });
      this.route('accounting.payments-payments', {
        path: '/accounting/payments'
      }, function () {
        this.route('edit-create-payment', {
          path: '/:cid/edit-create-payment'
        });
      });
      this.route('accounting.payments-payables', {
        path: '/accounting/payables'
      }, function () {
        this.route('edit-create-payable', {
          path: '/:pid/edit-create-payable'
        });
      });
      this.route('accounting.payments-funding', {
        path: '/accounting/funding'
      }, function () {
        this.route('edit-create-funding', {
          path: '/:fid/edit-create-funding'
        });
      });
      this.route('accounting.payments-financing', {
        path: '/accounting/financing'
      });
      this.route('information', function () {
        this.route('manage-adt', function () {
          this.route('edit-create-customer-contact', {
            path: '/:ccid/edit-create-customer-contact'
          });
          this.route('manage-account');
        });
        this.route('manage-adc');
        this.route('manage-alula');
        this.route('manage-cms', function () {
          this.route('edit-create-customer-contact', {
            path: '/:ccid/edit-create-customer-contact'
          });
        });
        this.route('manage-rapid-response');
        this.route('manage-united-central-control');
        this.route('manage-avantguard');
        this.route('manage-dynamark-monitoring', function () {
          this.route('edit-create-customer-contact', {
            path: '/:ccid/edit-create-customer-contact'
          });
        });
        this.route('manage-security-partners', function () {
          this.route('edit-create-customer-contact', {
            path: '/:ccid/edit-create-customer-contact'
          });
        });
        this.route('manage-legacy-security-services', function () {
          this.route('edit-create-customer-contact', {
            path: '/:ccid/edit-create-customer-contact'
          });
        });
        this.route('manage-american-two-way', function () {
          this.route('edit-create-customer-contact', {
            path: '/:ccid/edit-create-customer-contact'
          });
        });
        this.route('manage-affiliated-monitoring', function () {
          this.route('edit-create-customer-contact', {
            path: '/:ccid/edit-create-customer-contact'
          });
        });
        this.route('manage-cops-monitoring', function () {
          this.route('edit-create-customer-contact', {
            path: '/:ccid/edit-create-customer-contact'
          });
        });
        this.route('manage-api-monitoring', function () {
          this.route('edit-create-customer-contact', {
            path: '/:ccid/edit-create-customer-contact'
          });
        });
        this.route('manage-alarm-central', function () {
          this.route('edit-create-customer-contact', {
            path: '/:ccid/edit-create-customer-contact'
          });
        });
        this.route('manage-esc-central', function () {
          this.route('edit-create-customer-contact', {
            path: '/:ccid/edit-create-customer-contact'
          });
        });
        this.route('manage-all-american-monitoring');
        this.route('manage-guardian-protection', function () {
          this.route('edit-create-customer-contact', {
            path: '/:ccid/edit-create-customer-contact'
          });
        });
        this.route('manage-brinks-home-security', function () {
          this.route('edit-create-customer-contact', {
            path: '/:ccid/edit-create-customer-contact'
          });
        });
        this.route('manage-telus');
      });
      this.route('payment-invoice', {
        path: '/payment-invoice/:pid'
      });
      this.route('contacts', function () {
        this.route('edit-create-customer-contact', {
          path: '/:ccid/edit-create-customer-contact'
        });
      });
      this.route('history');
      this.route('files', function () {
        this.route('upload-file', {
          path: '/:fid/upload-file'
        });
      });
      this.route('messages', function () {
        this.route('create-message', {
          path: '/:mid/create-message'
        });
      });
      this.route('map');
      this.route('at-risk-entries', function () {
        this.route('edit-create-at-risk', {
          path: '/:arid/edit-create-at-risk'
        });
      });
      this.route('notes', function () {
        this.route('edit-create-note', {
          path: '/:nid/edit-create-note'
        });
        this.route('edit-create-task', {
          path: '/:tid/edit-create-task'
        });
      });
      this.route('appointments', function () {
        this.route('edit-create-appointment', {
          path: '/:ceid/edit-create-appointment'
        });
        this.route('check-availability');
      });
      this.route('work-orders', function () {
        this.route('edit-create-work-order', {
          path: '/:wid/edit-create-work-order'
        }, function () {
          this.route('edit-create-appointment', {
            path: '/edit-create-appointment/:ceid'
          });
        });
      });
    });
    this.route('customer-list');
    this.route('leads', function () {
      //lead index route to show the leads-list
      this.route('index', {
        path: '/'
      });
      this.route('information', {
        path: '/:id/information'
      }, function () {
        this.route('edit-create-interaction', {
          path: '/:iid/edit-create-interaction'
        });
      });
      this.route('dashboard', {
        path: '/:id/dashboard'
      }, function () {
        this.route('edit-create-note', {
          path: '/:nid/edit-create-note'
        });
        this.route('edit-create-interaction', {
          path: '/:iid/edit-create-interaction'
        });
        this.route('edit-create-quote', {
          path: '/:qid/edit-create-quote'
        });
        this.route('edit-create-appointment', {
          path: '/:ceid/edit-create-appointment'
        });
      });
      this.route('accounting', {
        path: '/:id/accounting'
      }, function () {
        this.route('edit-create-quote', {
          path: '/:qid/edit-create-quote'
        });
      });
      this.route('messages', {
        path: '/:id/messages'
      }, function () {
        this.route('create-message', {
          path: '/:mid/create-message'
        });
      });
      this.route('history', {
        path: '/:id/history'
      });
      this.route('files', {
        path: '/:id/files'
      }, function () {
        this.route('upload-file', {
          path: '/:fid/upload-file'
        });
      });
      this.route('notes', {
        path: '/:id/notes'
      }, function () {
        this.route('edit-create-note', {
          path: '/:nid/edit-create-note'
        });
      });
      this.route('appointments', {
        path: '/:id/appointments'
      }, function () {
        this.route('edit-create-appointment', {
          path: '/:ceid/edit-create-appointment'
        });
      });
    });
    this.route('reports', function () {
      this.route('view', {
        path: '/:id'
      });
      this.route('my-reports');
      this.route('customer-reports');
      this.route('equipment-reports');
      this.route('funding-reports');
      this.route('lead-reports');
      this.route('note-ticket-reports');
      this.route('payable-reports');
      this.route('user-reports');
      this.route('adt-reports');
      this.route('telus-reports');
      this.route('accounting-reports');
      this.route('technician-reports');
      this.route('task-reports');
      this.route('work-order-reports');
      this.route('calendar-event-reports');
    });
    this.route('login');
    this.route('administration', function () {
      this.route('mobile-navigation');
      this.route('org-chart', {
        path: '/org-chart/:chart_id'
      }, function () {
        this.route('user', {
          path: '/user/:user_id'
        });
        this.route('unassigned');
      });
      this.route('account-management', function () {
        this.route('billing');
        this.route('add-ons');
      });
      this.route('human-resources', function () {
        this.route('organization');
        this.route('permissions');
        this.route('permissions.group-details', {
          path: '/permissions/group-details/:id'
        });
        this.route('permissions.group-details.grants-policies', {
          path: '/permissions/group-details/:id/grants-policies/:sectionkey'
        });
        this.route('user-list');
      });
      this.route('inventory', function () {
        this.route('company-replenishment');
        this.route('dashboard');
        this.route('equipment-templates');
        this.route('equipment-transfer');
        this.route('inventory-ledger');
        this.route('on-hand');
        this.route('physical-inventory');
        this.route('purchase-orders');
        this.route('purchase-requisitions');
        this.route('rma');
        this.route('spillage');
      });
      this.route('company-settings', function () {
        this.route('content-management', function () {
          this.route('appointment-subtypes');
          this.route('account-classes');
          this.route('activation-fees');
          this.route('contract-terms');
          this.route('customer-cancel-reasons');
          this.route('customer-warranties');
          this.route('dealer-numbers');
          this.route('dry-run-reasons');
          this.route('funding');
          this.route('invoice-billable-items');
          this.route('monthly-monitoring-rates');
          this.route('note-types');
          this.route('order-templates');
          this.route('customer-workflows');
          this.route('customer-payment-types');
          this.route('work-order-templates');
          this.route('task-templates');
          this.route('work-order-types');
          this.route('scheduling-profiles');
          this.route('at-risk-entry-settings');
          this.route('message-templates');
          this.route('lead-statuses');
          this.route('equipment-return-reasons');
          this.route('customer-notes-contact-settings');
          this.route('invoice-quote-templates');
          this.route('invoice-terms');
        });
        this.route('system', function () {
          this.route('state-sales-tax-settings');
          this.route('tags'); //Route built for NRG that is no longer needed
          //this.route('upload-tool');

          this.route('form-defaults', function () {
            this.route('customer');
            this.route('lead');
            this.route('scheduling');
          });
          this.route('payables');
          this.route('payables.function-details', {
            path: '/payables/function-details/:id'
          });
          this.route('payables.function-details.level-details', {
            path: '/payables/function-details/level-details/:lid'
          });
          this.route('payables.function-details.level-details.line-item-details', {
            path: '/payables/function-details/level-details/line-item-details/:liid'
          });
          this.route('global-settings', function () {
            this.route('dashboard');
            this.route('company');
            this.route('notifications');
            this.route('email');
            this.route('accounting');
            this.route('inventory');
            this.route('file-import');
            this.route('calendar');
          });
        });
        this.route('organization', function () {
          this.route('office-locations');
          this.route('user-titles');
        });
        this.route('equipment', function () {
          this.route('equipment');
          this.route('equipment-vendors');
          this.route('equipment-kits');
          this.route('equipment-packages');
        });
        this.route('integrations', function () {
          this.route('accounting-providers-sync');
          this.route('interactive-services-providers');
          this.route('partner-company-list');
          this.route('monitoring-company-list');
          this.route('electronic-document-providers', function () {
            this.route('edit-create-electronic-document-provider', {
              path: '/edit-create-electronic-document-provider/:edpid'
            });
          });
          this.route('partner-companies', {
            path: '/partner-companies/:id'
          }, function () {
            this.route('company');
            this.route('campaigns');
            this.route('leads');
            this.route('customers');
            this.route('payables');
            this.route('files');
          });
          this.route('payment-processing');
          this.route('credit-bureaus');
          this.route('consumer-financing');
        });
      });
    });
    this.route('my-account', function () {
      this.route('information');
      this.route('login-information');
      this.route('integrations');
      this.route('notifications');
      this.route('payables', function () {
        this.route('edit-payable', {
          path: '/edit-payable/:pid'
        });
      });
      this.route('time-off');
      this.route('roles');
    });
    this.route('my-inventory', function () {
      this.route('equipment-inventory');
      this.route('physical-inventory');
      this.route('purchase-orders');
      this.route('transfers');
      this.route('spillage');
      this.route('purchase-requisitions');
    });
    this.route('users', {
      path: '/users/:id'
    }, function () {
      this.route('information');
      this.route('human-resources');
      this.route('roles');
      this.route('permissions');
      this.route('login-information');
      this.route('history');
      this.route('time-off');
      this.route('inventory');
      this.route('notifications');
      this.route('files');
      this.route('integrations');
    });
    this.route('home', function () {
      this.route('tech-today');
      this.route('tech-today.appointment', {
        path: '/tech-today/appointment/:id'
      }, function () {
        this.route('create-invoice', {
          path: '/:iid/create-invoice'
        });
        this.route('create-task', {
          path: '/create-task/:tid'
        });
        this.route('create-message', {
          path: '/create-message/:mid'
        });
        this.route('edit-create-customer-equipment', {
          path: '/:ceid/edit-create-customer-equipment'
        });
        this.route('manage-adt', function () {
          this.route('edit-create-customer-contact', {
            path: '/:ccid/edit-create-customer-contact'
          });
          this.route('manage-account');
        });
        this.route('manage-adc');
        this.route('manage-alula');
        this.route('manage-cms');
        this.route('manage-rapid-response');
        this.route('manage-united-central-control');
        this.route('manage-avantguard');
        this.route('manage-dynamark-monitoring');
        this.route('manage-security-partners');
        this.route('manage-legacy-security-services');
        this.route('manage-american-two-way');
        this.route('manage-affiliated-monitoring');
        this.route('manage-cops-monitoring');
        this.route('manage-api-monitoring');
        this.route('manage-alarm-central');
        this.route('manage-esc-central');
        this.route('manage-all-american-monitoring');
        this.route('manage-guardian-protection');
        this.route('manage-brinks-home-security');
      });
      this.route('at-risk', function () {
        this.route('edit-at-risk', {
          path: '/edit-at-risk/:id'
        });
      });
      this.route('view', function () {
        this.route('edit-task', {
          path: '/edit-task/:id'
        });
        this.route('edit-appointment', {
          path: '/edit-appointment/:id'
        });
        this.route('edit-timeoff', {
          path: '/edit-timeoff/:id'
        });
        this.route('edit-at-risk', {
          path: '/edit-at-risk/:id'
        });
        this.route('edit-payable', {
          path: '/edit-payable/:id'
        });
        this.route('edit-work-order', {
          path: '/edit-work-order/:id'
        }, function () {
          this.route('edit-create-appointment', {
            path: '/edit-create-appointment/:ceid'
          });
        });
      });
      this.route('tasks', function () {
        this.route('edit-task', {
          path: 'edit-task/:id'
        });
      });
      this.route('work-orders', function () {
        this.route('edit-create-work-order', {
          path: 'edit-create-work-order/:id'
        }, function () {
          this.route('edit-create-appointment', {
            path: '/edit-create-appointment/:ceid'
          });
        });
        this.route('edit-create-appointment', {
          path: '/edit-create-appointment/:ceid'
        });
        this.route('edit-timeoff', {
          path: '/edit-timeoff/:id'
        });
      });
    });
    this.route('all-tasks', function () {
      this.route('edit-task', {
        path: 'edit-task/:id'
      });
    });
    this.route('all-at-risks', function () {
      this.route('edit-at-risk', {
        path: 'edit-at-risk/:id'
      });
    });
    this.route('all-work-orders', function () {
      this.route('edit-work-order', {
        path: '/edit-work-order/:id'
      }, function () {
        this.route('edit-create-appointment', {
          path: '/edit-create-appointment/:ceid'
        });
      });
    });
    this.route('all-appointments', function () {
      this.route('edit-appointment', {
        path: 'edit-appointment/:id'
      });
    });
    this.route('calendar', function () {
      this.route('index', {
        path: '/'
      }, function () {
        this.route('edit-create-work-order', {
          path: '/edit-create-work-order/:id'
        });
        this.route('edit-create-appointment', {
          path: '/edit-create-appointment/:id'
        });
      });
      this.route('map-view', function () {
        this.route('edit-work-order', {
          path: '/edit-work-order/:id'
        });
        this.route('edit-appointment', {
          path: '/edit-appointment/:id'
        });
      });
    });
    this.route('accounting', function () {
      this.route('dashboard');
      this.route('funding-entries', function () {
        this.route('edit-funding', {
          path: '/edit-funding/:fid'
        });
      });
      this.route('payments', function () {
        this.route('edit-payment', {
          path: '/edit-payment/:cid'
        });
      });
      this.route('payable-entries', function () {
        this.route('edit-payable', {
          path: '/edit-payable/:pid'
        });
      });
      this.route('invoices', function () {
        this.route('edit-invoice', {
          path: '/edit-invoice/:iid'
        });
      });
      this.route('credit-memos', function () {
        this.route('edit-credit-memo', {
          path: '/edit-credit-memo/:mid'
        });
      });
      this.route('refund-receipts', function () {
        this.route('edit-refund-receipt', {
          path: '/edit-refund-receipt/:rid'
        });
      });
      this.route('quickbooks-ledger');
      this.route('aging-invoices');
    });
    this.route('customer_credit', {
      path: '/customer_credit/:id'
    });
    this.route('dialer-customer-return');
    this.route('dialer-customer-return', {
      path: '/dialer-customer-return/phone/:phone'
    }); //TODO: additional params such as ssn /

    this.route('demo', function () {
      this.route('confirm');
      this.route('copy-model');
      this.route('form-progress-bar');
      this.route('power-select');
      this.route('lists');
      this.route('glimmer');
      this.route('data-list');
      this.route('st-table');
      this.route('status-timeline');
      this.route('misc-input');
      this.route('user-card');
      this.route('svg-components');
      this.route('dashboard-config');
      this.route('background-gradiant');
      this.route('query-params');
      this.route('multi-step-form', function () {
        this.route('edit-create', {
          path: '/:id/edit-create'
        });
      });
      this.route('notifications');
      this.route('flex');
      this.route('grants');
      this.route('package-select');
      this.route('date-picker');
      this.route('wysiwyg');
      this.route('crop-image');
      this.route('pagination');
      this.route('st-card');
      this.route('multi-step-form-create', function () {
        this.route('edit-create', {
          path: '/:id/edit-create'
        });
      });
      this.route('st-await');
      this.route('st-model-watcher');
      this.route('grants-accessed-test-one');
      this.route('grants-accessed-test-two');
      this.route('monitoring-company-integrations');
    });
    this.route('loading');
    this.route('oauth', function () {
      this.route('callback');
    }); //catch-all for 404, bad routes

    this.route('not-found', {
      path: '/*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});