define("securitytrax-ember-ui/components/pages/app-login/component", ["exports", "moment", "lodash", "ember-concurrency", "@sentry/browser", "securitytrax-ember-ui/config/environment", "securitytrax-ember-ui/helpers/feature-is-enabled"], function (_exports, _moment, _lodash, _emberConcurrency, Sentry, _environment, _featureIsEnabled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    intl: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    username: null,
    password: null,
    errorMessage: null,

    init() {
      this._super(...arguments);

      this.newHighlightedFeatures = [{
        bodyTitle: this.intl.t('pages.login.payment_method_report_filter'),
        body: this.intl.t('pages.login.payment_method_report_filter_description'),
        imageSrc: '/assets/images/icn_payment_report.svg',
        featureLink: 'https://answers.securitytrax.com/Support/Company_and_Admin/Company/Reports'
      }, {
        bodyTitle: this.intl.t('pages.login.secure_pay_portal_new_look'),
        body: this.intl.t('pages.login.secure_pay_portal_new_look_description'),
        imageSrc: '/assets/images/secure_pay_screenshot.svg',
        featureLink: 'https://answers.securitytrax.com/Support/Company_and_Admin/Adminstration/Company_Settings/System/Customer_Payment_Portal'
      }, {
        bodyTitle: this.intl.t('pages.login.tech_today_at_work_managers'),
        body: this.intl.t('pages.login.tech_today_at_work_managers_description'),
        imageSrc: '/assets/images/icn_tech_today_at_work_managers.svg',
        featureLink: 'https://answers.securitytrax.com/Support/SecurityTrax_Home/User_Home/Tech_Today_and_Tech_at_Work_for_Managers'
      }, {
        bodyTitle: this.intl.t('pages.login.customer_account_aging'),
        body: this.intl.t('pages.login.customer_account_aging_description'),
        imageSrc: '/assets/images/icn_customer_account_aging.svg',
        featureLink: 'https://answers.securitytrax.com/Support/Company_and_Admin/Company/Company_Accounting/Company_Aging_Report'
      }];
      this.didYouKnowHighlightedFeatures = [{
        bodyTitle: this.intl.t('pages.login.lead_conversion_workflow'),
        body: this.intl.t('pages.login.lead_conversion_workflow_description'),
        imageSrc: '/assets/images/icn_lead_conversion_workflow.svg',
        featureLink: 'https://answers.securitytrax.com/Support/Leads/How_to_Convert_a_Lead_to_a_Customer'
      }, {
        bodyTitle: this.intl.t('pages.login.dashboard_config'),
        body: this.intl.t('pages.login.dashboard_config_description'),
        imageSrc: '/assets/images/icn_dashboard_config.svg',
        featureLink: 'https://answers.securitytrax.com/Support/SecurityTrax_Home/User_Home/010Active_User_Home_Dashboard#Configuring_your_Home_and_Customer_Dashboard_Layout'
      }, {
        bodyTitle: this.intl.t('pages.login.bulk_scheduler'),
        body: this.intl.t('pages.login.bulk_scheduler_description'),
        imageSrc: '/assets/images/icn_bulk_schedule.svg',
        featureLink: 'https://answers.securitytrax.com/Support/Customer_Management/Work_Orders_and_Appointments/Scheduling_Profiles#Bulk_Scheduler_Profile'
      }];
    },

    get pandaDocEnabled() {
      return (0, _featureIsEnabled.featureIsEnabled)('pandaDoc');
    },

    get featuresDivStyle() {
      return Ember.String.htmlSafe("background-image: url(/assets/images/login_background.png);");
    },

    copyright: Ember.computed(function () {
      return '© ' + (0, _moment.default)().format('YYYY');
    }),
    IDPAuthenticateTask: (0, _emberConcurrency.task)(function* (authIdentityProvider) {
      var currentURL = window.location.href;
      var loginIndex = currentURL.length - 5;
      var redirect_uri = currentURL.slice(0, loginIndex) + 'oauth/callback';
      authIdentityProvider.query_params.redirect_uri = redirect_uri;
      var authEndpoint = authIdentityProvider.authorization_endpoint;

      if (!authEndpoint) {
        var returnData = yield this.stAjax.request(authIdentityProvider.discovery_uri, {
          method: 'GET',
          headers: {}
        });
        authEndpoint = returnData.authorization_endpoint;
      }

      var params = '';
      Object.entries(authIdentityProvider.query_params).forEach(entry => {
        params += entry[0] + '=' + entry[1] + '&';
      });
      window.location.href = authEndpoint + '?' + params;
    }),
    authenticateTask: (0, _emberConcurrency.task)(function* () {
      var credentials = {
        username: this.username,
        password: this.password
      };

      try {
        this.session.set('forcePasswordUpdate', false);
        var attemptedTransition = this.session.get('attemptedTransition');

        if (attemptedTransition && attemptedTransition.targetName != 'home.view.index') {
          localStorage.removeItem('storedURL');
        }

        yield this.session.authenticate('authenticator:jwt', credentials);

        if (_environment.default.environment != 'test' || _environment.default['securitytrax-ember-ui']['api-e2e-test-config']['local-tests']['runTests'] || _environment.default['securitytrax-ember-ui']['api-e2e-test-config']['ci-tests']['runTests']) {
          var sessionDataHash = yield (0, _emberConcurrency.hash)({
            resetHeaders: this.stAjax.resetHeaders(),
            tokenData: this.sessionData.getTokenData(),
            userData: this.get('sessionData').getUser()
          }, 'Login session data hash');

          if (sessionDataHash.tokenData.fpc == true) {
            this.session.set('forcePasswordUpdate', true);
            this.session.trigger('forcePasswordUpdate');
          }

          if (_environment.default.environment != 'test' && sessionDataHash.tokenData && sessionDataHash.tokenData.sub) {
            Sentry.configureScope(scope => {
              scope.setUser({
                id: sessionDataHash.tokenData.sub.user_id,
                username: this.username
              });

              if (window.Cypress) {
                scope.setTag('cypress', true);
              }

              scope.setTag('company_id', sessionDataHash.tokenData.sub.company_id);
            });
          }
        }

        this.set('username', null);
        this.set('password', null);
      } catch (errorResponse) {
        if (_lodash.default.get(errorResponse, 'errors.length', 0) > 0 && _lodash.default.get(errorResponse, 'errors[0].status', false) == '403' || errorResponse.status == '403') {
          this.set('errorMessage', this.intl.t('pages.login.no_group'));
        } else if (_lodash.default.get(errorResponse, 'errors.length', 0) > 0 && _lodash.default.get(errorResponse, 'errors[0].status', false) == '401' || errorResponse.status == '401') {
          this.set('errorMessage', this.intl.t('pages.login.invalid_credentials') + ' ' + _lodash.default.get(errorResponse, 'json.errors[0].detail', ''));
        } else {
          this.set('errorMessage', this.intl.t('pages.login.login_failed') + ' ' + _lodash.default.get(errorResponse, 'json.errors[0].detail', ''));
        }

        this.set('password', null);
        throw errorResponse;
      }
    }),
    actions: {
      clickLoginButton() {
        document.querySelector('.login-submit').click();
      }

    }
  });

  _exports.default = _default;
});